import { Route } from "react-router";
import RequireAuth from "../../assets/utilities/RequireAuth/RequireAuth";
import { MyAccount } from "../../components/pages/userProfile/MyAccount/MyAccount";
import coursePrivateRoutes from "./coursePrivateRoutes";

const coursePrivateRouteRenderer = () => {
    return coursePrivateRoutes.map(route => {
        return (
            <Route key={route.id} path={route.path} element={<RequireAuth>{route.element}</RequireAuth>}>
                <Route key={Math.random() * 1000} index element={<MyAccount />} />
                {route.nestedRoutes?.map(round2Route => {
                    return <Route key={round2Route?.id} path={round2Route?.path} element={<RequireAuth>{round2Route?.element}</RequireAuth>} />;
                })}
            </Route>
        );
    });
};

export default coursePrivateRouteRenderer;
