//Configuration
import React from "react";

//Third party packages
import { BsFillCheckCircleFill } from "react-icons/bs";

//Components
import { useSendOtpByTokenMutation } from "../../../../Redux/features/Verification.js/verification";
import { useGetProfileDetailsWIthAuthQuery } from "../../../../Redux/features/userInfo/userApi";
import { OvalLoader } from "../../../shared/Loader/OvalLoader/OvalLoader";
import { EmailOtp } from "./EmailOtp";

export const Verification = () => {
    //API
    const { data: loggedInUserProfile, isLoading: loggedLoading } = useGetProfileDetailsWIthAuthQuery();
    const [sendOtpByToken, { isSuccess, isLoading }] = useSendOtpByTokenMutation();

    const { isEmailVerified, firstName } = loggedInUserProfile || {};

    let content;
    if (isLoading || loggedLoading) {
        content = <></>;
    } else if (!isEmailVerified) {
        content = (
            <div>
                <h3 className="text-[22px] font-Inter font-bold">Verify your email</h3>
                <p className="max-w-[320px] text-[14px]">
                    Hi <span className="font-semibold">{firstName}!</span> use the link below to verify your email and start enjoying songshari.com
                </p>
                <button
                    className="bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] w-[220px] py-1 text-white rounded-[4px] mt-[10px] focus:shadow-md flex justify-center items-center z-40"
                    onClick={() => sendOtpByToken()}
                    disabled={isLoading}
                >
                    {isSuccess ? "Sent otp" : isLoading ? <OvalLoader height={20} width={20} color="#FFFFFF" /> : "Verify email"}
                </button>
                {isSuccess && <EmailOtp />}
            </div>
        );
    } else if (isEmailVerified) {
        content = (
            <div>
                <div className="flex items-center gap-x-[10px]">
                    Your Profile is verified <BsFillCheckCircleFill className="text-green-500" />
                </div>
                <p className="text-[12px] font-outfit">Enjoy songshari.com</p>
            </div>
        );
    }
    return content;
};
