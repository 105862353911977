import React from "react";

//Third party package
import Card from "@mui/material/Card";

// components
import { CourseCategoryFilter } from "./CourseCategoryFilter";
import { CourseSkillLevelFilter } from "./CourseSkillLevelFilter";
import { CourseTypeFilter } from "./CourseTypeFilter";

export default function CourseFilterContainer() {
    return (
        <Card sx={{ height: "auto" }}>
            <CourseTypeFilter />
            <CourseCategoryFilter />
            <CourseSkillLevelFilter />
        </Card>
    );
}
