import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paymentMethod: "amarPay",
};

const paymentMethodSlice = createSlice({
    name: "paymentMethod",
    initialState,
    reducers: {
        setPaymentMethod: (state = initialState, action) => {
            state.paymentMethod = action.payload;
        },
    },
});

export default paymentMethodSlice.reducer;
export const { setPaymentMethod } = paymentMethodSlice.actions;
