import React from "react";

// components
import { MobileBackButton } from "../../../../shared/Components/MobileBackButton";
import CourseFilterContainer from "../LeftSideFilterContainer/CourseFilterContainer";

export const CourseFilterForMobile = () => {
    return (
        <div>
            <div className="lg:hidden">
                <MobileBackButton name="Filter" />
            </div>
            <CourseFilterContainer />
        </div>
    );
};
