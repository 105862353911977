import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsAttempted } from "../../../Redux/features/Course/CourseQuiz/courseMarkshitSlice";

const useBeforeUnload = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleBeforeUnload = () => {
            dispatch(setIsAttempted(false));
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [dispatch]);
};

export default useBeforeUnload;
