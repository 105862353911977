export const nextVideoDecisionMaker = (sections, currentStatus) => {
    const { section, module, content } = currentStatus || {};
    const sectionLength = section.length;
    const moduleLength = sections[section]?.module || null;
    const contentLength = sections[section]?.modules[module]?.content || null;

    // check what will be
    if (contentLength > content + 1) {
        return "nextContent";
    } else if (contentLength === content + 1) {
        // checking the last content and last module
        if (moduleLength === module + 1) {
            return "nextSection";
        } else {
            return "nextModule";
        }
    } else if (moduleLength > module + 1) {
        return "nextModule";
    } else if (moduleLength === module + 1) {
        return "nextSection";
    } else if (sectionLength > section + 1) {
        return "nextSection";
    }
};
