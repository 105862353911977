import React from "react";

export const QuizAnsCard = ({ item, quesIndex, setSelectedAnswer, selectedAns, setAnswers, answers }) => {
    const clickAnswerHandler = () => {
        const updatedAnswer = [...answers];
        updatedAnswer[quesIndex] = item;
        setAnswers(updatedAnswer);
        setSelectedAnswer(item);
    };
    return (
        <p
            className={`w-full bg-gray-100 text-center py-1 rounded-[4px] cursor-pointer hover:bg-gray-500 hover:text-white ${
                selectedAns === item && "bg-gray-500 text-white"
            }`}
            onClick={clickAnswerHandler}
        >
            {item}
        </p>
    );
};
