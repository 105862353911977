import React from "react";

//components
import clockIcon from "../../../../../assets/images/Course/chronometer.png";
import exclamationMark from "../../../../../assets/images/Course/exclamation.png";
import questionMark from "../../../../../assets/images/Course/question-mark.png";
import troofy from "../../../../../assets/images/Course/trophy.png";

export const QuizHeader = ({ data }) => {
    const { quiz } = data || {};
    const { question, timeCount, passingScore } = quiz || {};
    return (
        <section className="w-full grid grid-cols-2 gap-y-[40px] font-outfit p-[20px]">
            <div className="flex items-center gap-x-[5px]">
                <img src={questionMark} alt="quesIcon" className="w-[35px]" />
                <div>
                    <p className="font-medium">Questions</p> <p>{question?.length}</p>
                </div>
            </div>
            <div className="flex items-center gap-x-[10px]">
                <img src={clockIcon} alt="quesIcon" className="w-[35px]" />
                <div>
                    <p className="font-medium">Total time</p> <p>{timeCount} min</p>
                </div>
            </div>
            <div className="flex items-center gap-x-[10px]">
                <img src={troofy} alt="quesIcon" className="w-[35px]" />
                <div>
                    <p className="font-medium">Pass mark</p> <p>{passingScore}%</p>
                </div>
            </div>
            <div className="flex items-center gap-x-[10px]">
                <img src={exclamationMark} alt="quesIcon" className="w-[35px]" />
                <div>
                    <p className="font-medium">Negative mark</p> <p>not allowed</p>
                </div>
            </div>
        </section>
    );
};
