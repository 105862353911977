import { Collapse } from "antd";
import React, { useEffect } from "react";
import { CiLock, CiUnlock } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { setIsAttempted } from "../../../../../Redux/features/Course/CourseQuiz/courseMarkshitSlice";
import { setCurrenntQuizID } from "../../../../../Redux/features/Course/CourseQuiz/courseQuizSlice";
import { Modules } from "./Modules";

// Third party packages
const { Panel } = Collapse;

export const CourseAccessOutline = ({ sections }) => {
    const dispatch = useDispatch();

    const {
        section: lastUnlockedSection,
        module: lastUnlockedModule,
        content: lastUnlockedContent,
    } = useSelector(state => state.persistedReducer?.courseOutline);

    // set Default
    useEffect(() => {
        dispatch(dispatch(setIsAttempted(false)));
        dispatch(setCurrenntQuizID(sections[lastUnlockedSection]?.modules[lastUnlockedModule]?.quiz[0]?._id || ""));
    }, [sections, dispatch, lastUnlockedSection, lastUnlockedModule]);
    return (
        <Collapse accordion style={{ width: "100%" }}>
            <div className="text-[14px] text-black/80 px-[12px] py-[10px] font-Inter border-b-[1px]">
                <p className="font-semibold">
                    {sections?.length}/{sections?.length} modules seen
                </p>
                <p>Watch all the videos To get certificate </p>
            </div>
            {sections?.map((milestone, index) => {
                return (
                    <Panel
                        header={milestone.name}
                        key={milestone?._id}
                        extra={index > lastUnlockedSection ? <CiLock /> : <CiUnlock />}
                        disabled={index > lastUnlockedSection}
                    >
                        <div className="w-full flex flex-col">
                            <Modules
                                modules={milestone?.modules}
                                lastUnlockedContent={lastUnlockedContent}
                                lastUnlockedModule={lastUnlockedModule}
                                sectionIndex={index}
                                lastUnlockedSection={lastUnlockedSection}
                            />
                        </div>
                    </Panel>
                );
            })}
        </Collapse>
    );
};
