import React from "react";

import { Toaster } from "react-hot-toast";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import { Verification } from "./Verification";

export const MyAccount = () => {
    return (
        <div className="w-full bg-white">
            <Verification />
            <div className="my-12">
                <ChangeEmail />
            </div>
            <div className="my-12">
                <ChangePassword />
            </div>
            <Toaster />
        </div>
    );
};
