import React from "react";

// Components
import calender from "../../../assets/images/WhyWeAreBest/calendar.png";
import support from "../../../assets/images/WhyWeAreBest/interview.png";
import project from "../../../assets/images/WhyWeAreBest/verification.png";
import code from "../../../assets/images/WhyWeAreBest/web-programming.png";
import VarientAnimation from "../../../assets/utilities/Animation/VarientAnimation";

export const WhyWeAreBest = () => {
    const data = [
        {
            id: 1,
            img: code,
            name: "Content created by industry experts",
        },
        {
            id: 2,
            img: project,
            name: "Practical projects and assignments",
        },
        {
            id: 3,
            img: support,
            name: "Direct support from mentors",
        },
        {
            id: 4,
            img: calender,
            name: "Ease of learning from anywhere",
        },
    ];
    return (
        <div className="max-w-[1200px] mx-auto mt-[100px] px-[16px]">
            <h1 className="text-[24px] font-Poppins font-semibold">Why we are best?</h1>
            <div className="mt-[20px] lg:mt-[50px] grid grid-cols-1 lg:grid-cols-4 gap-[20px]">
                {data?.map((card, i) => {
                    return (
                        <VarientAnimation direction={i === 0 || i === 2 ? "up" : "down"} delay={i === 0 || i === 2 ? 0.3 : 0.5}>
                            <div
                                key={card?.id}
                                className="w-full h-[235px] mx-auto flex flex-col justify-center items-center py-[30px] shadow-[rgba(0,0,0,0.05)_00px_0px_0px_1px] hover:shadow-[rgba(67,71,85,0.27)_0px_0px_0.25em,_rgba(90,125,188,0.05)_0px_0.25em_1em] duration-300"
                            >
                                <img src={card?.img} alt="card" className="w-[80px]" />
                                <h3 className="font-Nunito font-semibold mt-[8px] w-1/2 text-center">{card?.name}</h3>
                            </div>
                        </VarientAnimation>
                    );
                })}
            </div>
        </div>
    );
};
