import "./App.css";
import MasterRoute from "./route/MasterRoute";

//CSS
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function App() {
    return (
        <div>
            <MasterRoute />
        </div>
    );
}

export default App;
