// Configuration
import React from "react";

// Third party packages
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { CiLock, CiUnlock } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { setIsAttempted, setIsPass, setScore, setTotal } from "../../../../../Redux/features/Course/CourseQuiz/courseMarkshitSlice";
import { setCurrenntQuizID } from "../../../../../Redux/features/Course/CourseQuiz/courseQuizSlice";
import { Contents } from "./Contents";
const { Panel } = Collapse;

export const Modules = ({ modules, lastUnlockedModule, lastUnlockedContent, sectionIndex, lastUnlockedSection }) => {
    const dispatch = useDispatch();
    const currentQuizID = useSelector(state => state.persistedReducer.quiz?.currentQuizID);

    // set current quiz id to redux store
    const modulClick = data => {
        const { quiz } = data || {};
        const quizInfo = quiz[0];
        if (currentQuizID !== quizInfo?._id) {
            dispatch(setIsAttempted(false));
        }
        dispatch(setCurrenntQuizID(quizInfo?._id || ""));

        // marksheet clear
        dispatch(setScore(0));
        dispatch(setTotal(0));
        dispatch(setIsPass(false));
    };

    // Logic How disabled is woking
    // Here are written two condition to manage disabled module
    // Logic 1 | sectionIndex < lastUnlockedSection : all the previous sections module will be unlocked / not disabled
    // Login 2 | index > lastUnlockedModule : In current section all the previous module and the current module will be unlocked and all the future modules will be disabled / Locked

    const isDisabled = index => {
        if (sectionIndex < lastUnlockedSection) {
            return false;
        } else if (index > lastUnlockedModule) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <div>
            {modules?.map((module, index) => {
                return (
                    <Collapse
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        accordion
                        style={{ width: "100%", marginBottom: "12px" }}
                        key={module?._id}
                    >
                        <Panel header={module?.name} disabled={isDisabled(index)} extra={index > lastUnlockedModule ? <CiLock /> : <CiUnlock />}>
                            <div className="w-full flex flex-col" onClick={() => modulClick(module)}>
                                <Contents contents={module?.contents} lastUnlockedContent={lastUnlockedContent} />
                            </div>
                        </Panel>
                    </Collapse>
                );
            })}
        </div>
    );
};
