import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    section: 0,
    module: 0,
    content: 0,
};

const courseOutlineSlice = createSlice({
    name: "courseOutline",
    initialState,
    reducers: {
        loadCurrentStatus: (state = initialState, action) => {
            const { content, module, section } = action.payload || {};
            state.section = section;
            state.module = module;
            state.content = content;
        },
        loadNextVideo: (state = initialState, action) => {
            state.content = state.content + 1;
        },
        loadNextModule: (state = initialState, action) => {
            state.module = state.module + 1;
            state.content = 0;
        },
        loadNextSection: (state = initialState, action) => {
            state.section = state.section + 1;
            state.module = 0;
            state.content = 0;
        },
    },
});

export default courseOutlineSlice.reducer;
export const { loadCurrentStatus, loadNextVideo, loadNextModule, loadNextSection } = courseOutlineSlice.actions;
