import React from "react";

//Third party package
import { useNavigate } from "react-router-dom";

//components
import lottie from "lottie-web";
import { useEffect, useRef } from "react";
import bannerLottie from "../../../assets/lottie/online-learning.json";
import VarientAnimation from "../../../assets/utilities/Animation/VarientAnimation";

export const Banner = () => {
    const navigate = useNavigate();
    const animationContainer = useRef(null);

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg", // Choose the appropriate renderer (svg, canvas, html)
            loop: true,
            autoplay: true,
            animationData: bannerLottie,
        });

        return () => {
            // Clean up the animation when the component unmounts
            anim.destroy();
        };
    }, []);

    return (
        <div className="flex flex-col pt-[20px] lg:flex-row justify-evenly h-full lg:h-[calc(100vh-90px)] items-center px-[20px] max-w-[1400px] mx-auto">
            <div>
                <VarientAnimation direction="up" delay={0.3}>
                    <h1 className="text-[32px] lg:text-[56px] max-w-[550px] font-semibold font-Poppins">Learn on your own, build confidence!</h1>
                </VarientAnimation>
                <VarientAnimation direction="up" delay={0.5}>
                    <p className="text-gray-400 text-[14px]">Gain today's most important skills by enrolling in a career track program of choice.</p>
                </VarientAnimation>
                <VarientAnimation direction="up" delay={0.7}>
                    <button
                        type="button"
                        className="px-4 py-3  rounded-full text-white outline-none focus:ring-2 shadow-lg opacity-80 transform active:scale-x-90 transition-transform flex mt-[16px] bg-[linear-gradient(171deg,rgb(233,11,200)_41%,rgb(166,2,247)_100%)]"
                        onClick={() => navigate("/courses")}
                    >
                        <span className="font-medium font-outfit">View All Courses</span>
                    </button>
                </VarientAnimation>
            </div>
            <div>
                {/* <img src={bannerImg} alt="bannerImage" loading="lazy" /> */}
                <div ref={animationContainer}></div>
            </div>
        </div>
    );
};
