//Configuration
import React from "react";

//Third party package
import { BiFilter } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

//Components
import useDocumentTitle from "../../../../assets/utilities/useDocumentTitle";
import { MobileBackButton } from "../../../shared/Components/MobileBackButton";
import CustomHeader from "../../../shared/CustomHeader/CustomHeader";
import CourseFilterContainer from "./LeftSideFilterContainer/CourseFilterContainer";
import { RightSideCourses } from "./RightSideCourses/RightSideCourses";

export const CourseLandingPage = () => {
    const navigate = useNavigate();

    useDocumentTitle("Shongshari | Course");

    return (
        <>
            <div className="lg:hidden">
                <MobileBackButton name="Courses" />
            </div>
            <div className="hidden lg:block">
                <CustomHeader title="Courses" />
            </div>
    
            <div className="max-w-[1200px] mx-auto h-auto pt-[20px] lg:flex gap-x-[20px] relative px-[16px]">
                <div className="w-[310px] hidden lg:block">
                    <CourseFilterContainer />
                </div>
                <div className="block lg:hidden mb-[10px]">
                    <button
                        className="bg-white border-[1px] border-[#d0deef] w-full flex justify-center items-center h-[52px] rounded-[4px] px-[15px] text-[18px] text-[#585e8c]"
                        onClick={() => navigate("/courseFilter")}
                    >
                        <BiFilter className="text-[24px]" />
                        Filter
                    </button>
                </div>
                <div className="flex-1">
                    <RightSideCourses />
                </div>
            </div>
        </>
    );
};
