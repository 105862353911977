import React from "react";
import ReactPlayer from "react-player";
import { Modal } from "../../../../shared/Components/Modal";

const CourseVideoIntro = ({ modalControll, video }) => {
    return (
        <Modal modalControll={modalControll} px="px-0">
            <div className="relative">
                <ReactPlayer width="100%" height="280px" url={video} />
            </div>
        </Modal>
    );
};

export default CourseVideoIntro;
