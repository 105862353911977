import React from "react";
import { AiOutlineClose } from "react-icons/ai";

export const Modal = ({ children, modalControll, px }) => {
    return (
        <>
            <div className="fixed w-full h-full duration-300 inset-0 z-10 bg-black/50 cursor-pointer" onClick={modalControll}></div>
            <div
                className={`rounded w-[400px] lg:w-[700px] space-y-8 bg-white py-[37px] ${
                    px ? px : "px-[30px]"
                } fixed top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2`}
                style={{ border: "3px solid #ff317b" }}
            >
                <AiOutlineClose onClick={modalControll} className="absolute right-[30px] top-[20px]" />
                {children}
            </div>
        </>
    );
};
