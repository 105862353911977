import React from "react";

// Third party packages
import { TbCurrencyTaka } from "react-icons/tb";
import { useNavigate } from "react-router";
import { useGetProfileDetailsWIthAuthQuery } from "../../../../Redux/features/userInfo/userApi";

export const CourseCard = ({ course }) => {
    const navigate = useNavigate();

    // REDUX API CALL
    const { data: myProfile } = useGetProfileDetailsWIthAuthQuery();
  const courseDiscount = myProfile?.userMatrimonyPackageInfo?.package?.courseDiscount
  const discount = myProfile?.coursePackage?.package?.discount;
  console.log(myProfile)
    // vairable
    const { name, price, image, skillLevel, _id } = course || {};

    return (
      <abbr title={course?.description} className="no-underline">
        <div className="h-[430px] overflow-hidden relative w-full lg:w-[260px] mx-auto rounded-[4px] bg-white shadow-[rgba(0,0,0,0.05)_0px_6px_24px_0px,rgba(0,0,0,0.08)_0px_0px_0px_1px] hover:shadow-[rgba(0,0,0,0.16)_0px_10px_36px_0px,rgba(0,0,0,0.06)_0px_0px_0px_1px] duration-300 ">
          <div className="relative">
            <img
              className="h-[234px] w-full rounded-tl-[4px] rounded-tr-[4px]"
              src={image}
              alt="courseImage"
              loading="lazi"
            />
            <div className="absolute top-[9px] left-[10px] bg-pink-500 bg-opacity-40 backdrop-blur-xl shadow-md text-white text-[10px] px-2 py-1 font-Nunito font-normal tracking-wider">
              {skillLevel}
            </div>
          </div>
          <div className="content w-full h-[calc(430px-234px)] flex flex-col justify-between p-[10px]">
            <h3
              className="text-[19px] text-left font-bold font-Nunito cursor-pointer hover:text-black text-[#00000092]"
              style={{ zIndex: 1000 }}
              onClick={() => navigate(`/courses/${_id}`)}
            >
              {name}
            </h3>
            <div className="flex flex-col gap-y-[10px]">
              <div className="flex items-center text-[22px] font-SourceCodePro">
                {courseDiscount !== 0 && courseDiscount !== undefined && (
                  <span className="flex items-center">
                    <TbCurrencyTaka className="text-gray-400" />
                    <del className="text-gray-400 font-normal">{price}</del>
                  </span>
                )}
                <span className="flex items-center">
                  <TbCurrencyTaka />
                  <span className="text-gray-600 font-bold">
                    {price - ((price * courseDiscount) / 100 || 0)}
                  </span>
                </span>
              </div>
              <div className="w-full">
                <button
                  className="w-full h-[44px] special_profile_button font-Nunito text-[20px] font-medium"
                  onClick={() => navigate(`/course/checkout/${_id}`)}
                >
                  Book now
                </button>
              </div>
            </div>
          </div>
          {discount && discount !== 0 && (
            <span className="absolute top-8 -right-8 text-white px-6 rotate-[45deg] bg-[#E90BC8]">
              {discount}% discount
            </span>
          )}
        </div>
      </abbr>
    );
};
