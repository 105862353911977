import { Rating } from "@mui/material";
import React from "react";

// Third party packages
import { AiFillPlayCircle } from "react-icons/ai";
import { BsQuote } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import customFunc from "../../../../assets/utilities/customFunc";

export const TopReviewCard = ({ review }) => {
    const navigate = useNavigate();

    // distructure
    const { course, user, rating, review: reviewText } = review || {};
    const { name: courseName, _id: courseId } = course || {};
    const { firstName, profilePhoto, lastName } = user || {};

    const { profilePhotoDecisionMaker } = customFunc;

    // go to single course page handler
    const navigationHandler = () => {
        navigate(`/courses/${courseId}`);
    };
    return (
        <div className="w-[350px] bg-white ring-1 ring-black/20 p-[16px] rounded-[2px] mx-auto">
            <BsQuote className="text-[25px]" />
            <p>{reviewText}</p>
            <div className="mt-[10px] flex items-center gap-x-[10px] border-b-[1px] pb-[10px] border-black/50">
                <img src={profilePhotoDecisionMaker(profilePhoto)} className="w-[40px] h-[40px] rounded-full" alt="profile" />
                <div>
                    <p>{`${firstName} ${lastName}`}</p>
                    <Rating value={rating} size="small" readOnly />
                </div>
            </div>
            <div className="pt-[10px] flex items-center gap-x-[10px] cursor-pointer" onClick={navigationHandler}>
                <AiFillPlayCircle className="text-[22px]" />
                <p className="text-[14px] cursor-pointer text-gray-500 hover:text-black">{courseName}</p>
            </div>
        </div>
    );
};
