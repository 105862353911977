// import React from 'react';
import { useGetAllPackagesQuery } from "../../../Redux/features/package/packageApi";

// Configuration
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

// Components
import { useGetProfileDetailsWIthAuthQuery } from "../../../Redux/features/userInfo/userApi";
import { LineWaveLoader } from "../../shared/Loader/lineWaveLoader/LineWaveLoader";
import { PackageCard } from "../../shared/cards/PackagesCard/PackageCard";
import { ServerErrorMessage } from "../../ui/error/ServerErrorMessage";
import { SuccessSnackBar } from "../../ui/error/snackBar/SuccessSnackBar";

const PackageContainer = () => {
    const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
    const [packages, setPackages] = useState([]);
    const [hovered, setHovered] = useState(0);
    // const navigate = useNavigate();
    const { data, isLoading, error } = useGetAllPackagesQuery();
    const { data: profile } = useGetProfileDetailsWIthAuthQuery();

    useEffect(() => {
        if (data) {
            setPackages([...data?.coursePackages].sort((a, b) => a.priceMonth - b.priceMonth));
        }
    }, [data]);

    return (
        <div className="max-w-[1200px] mx-auto mt-8 mb-[100px]">
            <h6 className="text-center text-5xl font-semibold font-fira mb-[80px] gradient_text">Special Packages For You</h6>
            {isLoading && (
                <div className="my-16">
                    <LineWaveLoader title="Packages" />
                </div>
            )}

            {error && (
                <div className="flex items-center justify-center my-16">
                    <ServerErrorMessage />
                </div>
            )}
            {packages.length !== 0 && (
                <div className="px-[16px] grid grid-cols-1 md:grid-cols-2 my-16 lg:grid-cols-3 gap-4">
                    {packages?.map((pack, i) => (
                        <PackageCard
                            pack={pack}
                            packages={packages}
                            height="h-[450px]"
                            index={i}
                            user={profile?._id}
                            hovered={hovered}
                            setHovered={setHovered}
                            setSuccessSnackBarOpen={setSuccessSnackBarOpen}
                        />
                    ))}
                </div>
            )}
            {packages?.length === 0 && data && <div className="text-center text-3xl font-bold text-gray-400 my-16">No Packages Found</div>}
            <SuccessSnackBar
                {...{
                    successSnackBarOpen,
                    setSuccessSnackBarOpen,
                    message: "Successful",
                }}
            />
        </div>
    );
};

export default PackageContainer;
