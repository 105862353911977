import { Divider } from "antd";
import React from "react";
import { TbCurrencyTaka } from "react-icons/tb";

// Third party packages
import { BsGraphUp } from "react-icons/bs";
import { CiVideoOn } from "react-icons/ci";
import { FiHeadphones } from "react-icons/fi";
import { GrGroup, GrLanguage } from "react-icons/gr";
import { TbMessages } from "react-icons/tb";
import { useNavigate } from "react-router";

export const SingleCourseInfoCard = ({ course, summary }) => {
    const navigate = useNavigate();
    const { oldPrice, price, _id } = course || {};
    const { totalDuration, totalStudents } = summary || {};

    const supports = [
        {
            id: 1,
            title: `Approx ${totalDuration} hrs`,
            icon: <CiVideoOn className="text-[20px]" />,
        },
        {
            id: 2,
            title: `${totalStudents}+ Total Students`,
            icon: <GrGroup className="text-[20px]" />,
        },
        {
            id: 3,
            title: "Mentor Support",
            icon: <FiHeadphones className="text-[20px]" />,
        },
        {
            id: 4,
            title: "Basic To Advanced",
            icon: <BsGraphUp className="text-[20px]" />,
        },
        {
            id: 5,
            title: "Language Bangali",
            icon: <GrLanguage className="text-[20px]" />,
        },
        {
            id: 6,
            title: "Discussion Forum, Q/A",
            icon: <TbMessages className="text-[20px]" />,
        },
    ];

    return (
      <div className="w-full lg:max-w-[400px] h-auto lg:h-[400px] mx-auto ring-1 ring-inset ring-[#d0deef] rounded-[4px] p-[16px] sticky top-[44px]">
        <h3 className="text-[32px] font-Nunito font-bold">
          Included in this course
        </h3>
        <div className="my-[26px] grid grid-cols-2 gap-[20px]">
          {supports.map((item) => {
            return (
              <div className="flex items-center gap-x-[6px]" key={item.id}>
                <div>{item.icon}</div>
                <h3 className="text-[14px] text-gray-500 font-outfit">
                  {item.title}
                </h3>
              </div>
            );
          })}
        </div>
        <div className="h-[100px] bg-gray-100 w-full rounded-[4px] flex justify-around items-center">
          <span className="text-[16px] font-semibold font-SourceCodePro">
            Price
          </span>
          <Divider type="vertical" style={{ height: "70px" }} />
          <div className="flex flex-col gap-y-[6px] text-[18px] font-SourceCodePro">
            <del className="flex items-center justify-center">
              <TbCurrencyTaka className="text-gray-800" />
              {oldPrice}
            </del>
            <span className="flex items-center justify-center">
              <TbCurrencyTaka className="text-gray-800" />
              {price}
            </span>
          </div>
        </div>
        <button
          className={`w-full mt-[20px]  py-2 special_profile_button font-Nunito`}
          onClick={() => navigate(`/course/checkout/${_id}`)}
        >
          Buy Now
        </button>
      </div>
    );
};
