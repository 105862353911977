import emptyProfile from "../images/profile/emptyProfile.svg";

const customFunc = {
    profilePhotoDecisionMaker: photoUrl => {
        if (photoUrl) {
            return photoUrl;
        } else {
            return emptyProfile;
        }
    },
};

export default customFunc;
