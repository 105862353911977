import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filter: {
        types: "",
        skillLevel: "",
        category: "",
        sortByPrice: "",
        sortByRating: "rating",
        sortByDate: "",
    },
};

const courseFilterSlice = createSlice({
    name: "courseFilterV3",
    initialState,
    reducers: {
        setTypes: (state = initialState, action) => {
            // remove all selection
            // state.filter.skillLevel = "";
            // state.filter.category = "";

            // set types
            if (state.filter.types.includes(action.payload)) {
                state.filter.types = "";
                return;
            }
            state.filter.types = action.payload;
        },
        setSkillLevel: (state = initialState, action) => {
            // remove all selection
            // state.filter.category = "";
            // state.filter.types = "";

            // set skill level
            if (state.filter.skillLevel.includes(action.payload)) {
                state.filter.skillLevel = "";
                return;
            }
            state.filter.skillLevel = action.payload;
        },
        setCategory: (state = initialState, action) => {
            // remove all selection
            // state.filter.types = "";
            // state.filter.skillLevel = "";

            // set category
            if (state.filter.category.includes(action.payload)) {
                state.filter.category = "";
                return;
            }
            state.filter.category = action.payload;
        },
        setSortByPopularity: (state = initialState, action) => {
            // first step > remove other sort data
            state.filter.sortByDate = "";
            state.filter.sortByPrice = "";

            // second step > set sort data
            state.filter.sortByRating = "rating";
        },
        setSortByLatest: (state = initialState, action) => {
            // first step > remove other sort data
            state.filter.sortByRating = "";
            state.filter.sortByPrice = "";

            // second step > set sort data
            state.filter.sortByDate = "latest";
        },
        setSortByPrice: (state = initialState, action) => {
            // first step > remove other sort data
            state.filter.sortByRating = "";
            state.filter.sortByDate = "";

            // second step > set sort data

            state.filter.sortByPrice = action.payload;
        },
    },
});

export default courseFilterSlice.reducer;
export const { setTypes, setSkillLevel, setCategory, setSortByPopularity, setSortByLatest, setSortByPrice } = courseFilterSlice.actions;
