import { GoogleSignUpInfo } from "../../components/pages/Authentications/GoogleLogin/GoogleSignUpInfo";
import Bundle from "../../components/pages/Bundle/Bundle";
import { BundleCheckout } from "../../components/pages/Bundle/BundleCheckout/BundleCheckout";
import { CourseAccess } from "../../components/pages/Courses/CourseAccess/CourseAccess";
import { CourseCheckout } from "../../components/pages/Courses/CourseCheckout/CourseCheckout";
import { CourseSendReview } from "../../components/pages/Courses/CourseReview/CourseSendReview/CourseSendReview";
import { BundleCart } from "../../components/pages/bundleCart/BundleCart";
import Packages from "../../components/pages/packages/Packages";
import { MyAccount } from "../../components/pages/userProfile/MyAccount/MyAccount";
import { MyCourses } from "../../components/pages/userProfile/MyCourses/MyCourses";
import { SingleSupportDetailes } from "../../components/pages/userProfile/Support/SingleSupportDetailes/SingleSupportDetailesBody/SingleSupportDetailes";
import { SupportContainer } from "../../components/pages/userProfile/Support/SupportContainer";
import { UserProfile } from "../../components/pages/userProfile/UserProfile";
import { WalletContainer } from "../../components/pages/userProfile/Wallet/WalletContainer";
import { PaymentSuccess } from "../../components/shared/PaymentSuccess/PaymentSuccess";

const coursePrivateRoutes = [
  {
    id: 1,
    path: "/course/access/:id",
    element: <CourseAccess />,
  },
  {
    id: 2,
    path: "/course/review/:id",
    element: <CourseSendReview />,
  },
  {
    id: 3,
    path: "/course/checkout/:id",
    element: <CourseCheckout />,
  },
  {
    id: 4,
    path: "/packages",
    element: <Packages />,
  },
  {
    id: 5,
    path: "/bundle",
    element: <Bundle />,
  },
  {
    id: 6,
    path: "/bundle/checkout/:id",
    element: <BundleCheckout />,
  },
  {
    id: 7,
    path: "/bundle/:id",
    element: <BundleCart />,
  },
  {
    id: 8,
    path: "/dashboard",
    element: <UserProfile />,
    nestedRoutes: [
      {
        id: 1,
        path: "account",
        element: <MyAccount />,
      },
      {
        id: 2,
        path: "my-courses",
        element: <MyCourses />,
      },
      {
        id: 3,
        path: "my-wallet",
        element: <WalletContainer />,
      },
      {
        id: 4,
        path: "support",
        element: <SupportContainer />,
      },
      {
        id: 5,
        path: "support/:id",
        element: <SingleSupportDetailes />,
      },
    ],
  },
  {
    id: 9,
    path: "/payment_Success",
    element: <PaymentSuccess />,
  },
  {
    id: 10,
    path: "/registration-info",
    element: <GoogleSignUpInfo />,
  },
];

export default coursePrivateRoutes;
