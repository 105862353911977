// Configuration
import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";

// Components
import coursePrivateRouteRenderer from "./Course/coursePrivateRouteRenderer";
import coursePublicRouteRenderer from "./Course/coursePublicRouteRenderer";

const MasterRoute = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Course Private Routes */}
                {coursePrivateRouteRenderer()}

                {/* Course Public routes */}
                {coursePublicRouteRenderer()}
            </Routes>
        </BrowserRouter>
    );
};

export default MasterRoute;
