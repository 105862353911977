import { Progress } from "antd";
import React from "react";

export const Marksheet = ({ userQuiz, quiz }) => {
    const { isPassed, score } = userQuiz || {};
    const { question } = quiz || {};
    const total = question?.length;
    // const { score, total, isPassed } = useSelector(state => state.persistedReducer.courseMarkSheet);
    return (
        <div className="w-full h-[230px] flex flex-col justify-center items-center ">
            <Progress
                percent={(score / total) * 100}
                type="circle"
                status="active"
                strokeColor={isPassed ? "#22c55e" : "#EF4444"}
                format={percent => (
                    <p className={`flex flex-col items-center justify-center ${isPassed ? "text-green-500" : "text-red-500"}`}>
                        <span>{percent}%</span>
                        <span className={`text-[12px] mt-[3px] ${isPassed ? "text-green-500" : "text-red-500"}`}>
                            {isPassed ? "Passed" : "Failed"}
                        </span>
                    </p>
                )}
            />
            <p className="text-[16px] font-Inter text-gray-600">You already attempted in this quiz.</p>
            <p>
                Score: {score}/{total}
            </p>
        </div>
    );
};
