// Configuration
import React, { useState } from "react";

// Third party packages
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import { useGetAllCoursesQuery } from "../../../../../Redux/features/Course/courseApi";
import courseIcon from "../../../../../assets/images/Course/online-learning.png";
import { LineWaveLoader } from "../../../../shared/Loader/lineWaveLoader/LineWaveLoader";
import { CourseCard } from "../../../../shared/cards/courses/CourseCard";
import { ServerErrorMessage } from "../../../../ui/error/ServerErrorMessage";
import { CoursesCountBox } from "./CoursesCountBox";

export const RightSideCourses = () => {
    const [page, setPage] = useState(1);
    const { types, skillLevel, category, sortByPrice, sortByRating, sortByDate } =
        useSelector(state => state.persistedReducer.courseFilter.filter) || {};

    // get data by filtering
    const { data, isLoading, isError, isFetching } = useGetAllCoursesQuery({
        page: page,
        limit: 9,
        categroyId: category,
        typeId: types,
        skill: skillLevel,
        sortByPrice: sortByPrice,
        sortByRating: sortByRating,
        sortByDate: sortByDate,
    });

    const { count, total, courses } = data || {};

    const pageCounting = Math.ceil(total / 9);

    // Get all courses
    let content;
    if (isLoading || isFetching) {
        content = <LineWaveLoader />;
    } else if (!isLoading && isError) {
        content = <ServerErrorMessage />;
    } else if (!isLoading && courses?.length === 0) {
        content = (
            <div className="w-full h-[350px] flex justify-center items-center font-Inter text-gray-500 flex-col text-[20px] gap-y-[10px]">
                <img src={courseIcon} className="w-[40px]" alt="courseIcon" />
                <p>No Course found!</p>
                <p className="text-[12px] text-center lg:w-1/2 ">
                    The system could not locate any courses matching your search criteria. Please double-check the course name or Filter and try
                    again. If you believe this is an error, please contact the administrator for further assistance.
                </p>
            </div>
        );
    } else if (!isLoading && courses?.length > 0) {
        content = (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[20px]">
                {courses?.map(item => {
                    return <CourseCard key={item?._id} course={item} />;
                })}
            </div>
        );
    }
    return (
        <div className="flex flex-col w-full">
            <CoursesCountBox {...{ count, total, page }} />
            <div>{content}</div>
            {total > 8 && (
                <div className="my-[100px] w-full flex justify-center">
                    <Pagination count={pageCounting} variant="outlined" color="secondary" onChange={(event, value) => setPage(value)} />
                </div>
            )}
        </div>
    );
};
