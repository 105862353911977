import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    score: 0,
    total: 0,
    isPassed: false,
    isAttemted: false,
};

const courseMarkshitSlice = createSlice({
    name: "courseMarksheet",
    initialState,
    reducers: {
        setScore: (state = initialState, action) => {
            state.score = action.payload;
        },
        setTotal: (state = initialState, action) => {
            state.total = action.payload;
        },
        setIsPass: (state = initialState, action) => {
            state.isPassed = action.payload;
        },
        setIsAttempted: (state = initialState, action) => {
            state.isAttemted = action.payload;
        },
    },
});

export default courseMarkshitSlice.reducer;
export const { setScore, setTotal, setIsPass, setIsAttempted } = courseMarkshitSlice.actions;
