import React from "react";
import { useGetTopThreeReviewsQuery } from "../../../Redux/features/Course/courseApi";
import { LineWaveLoader } from "../../shared/Loader/lineWaveLoader/LineWaveLoader";
import { TopReviewCard } from "../../shared/cards/courses/TopReviewCard";
import { ServerErrorMessage } from "../../ui/error/ServerErrorMessage";

export const StudentReviews = () => {
    const { data: Topreviews, isLoading, isError, isFetching } = useGetTopThreeReviewsQuery();

    const { count, reviews } = Topreviews || {};

    let content;
    if (isLoading || isFetching) {
        content = (
            <div>
                <LineWaveLoader />
            </div>
        );
    } else if (!isLoading && isError) {
        content = (
            <div>
                <ServerErrorMessage />
            </div>
        );
    } else if (!isLoading && count === 0) {
        content = <div>No Data Found!</div>;
    } else if (!isLoading && count > 0) {
        content = (
            <div className="my-[50px] flex flex-wrap items-center gap-[20px]">
                {reviews?.map(review => {
                    return <TopReviewCard key={review?._id} review={review} />;
                })}
            </div>
        );
    }

    return (
        <div className=" mt-[100px] bg-slate-50 py-[50px]">
            <div className="px-[16px] max-w-[1200px] mx-auto">
                <h3 className="text-[32px] font-bold font-Inter text-left">How learners like you are achieving their goals</h3>
                {content}
            </div>
        </div>
    );
};
