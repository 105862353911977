/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

const Countdown = ({ minutes, seconds, quizEnd }) => {
    const [remainingTime, setRemainingTime] = useState(Number(minutes) * 60 + seconds);

    useEffect(() => {
        if (remainingTime <= 0) {
            // Countdown has reached zero, do something
            // For example, display a message or call a function
            console.log("Countdown finished!");

            // close quiz
            quizEnd();
            return;
        } else {
            const timer = setInterval(() => {
                setRemainingTime(prevTime => prevTime - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [remainingTime]);

    // Convert remaining time to minutes and seconds
    const minutesDisplay = Math.floor(remainingTime / 60);
    const secondsDisplay = remainingTime % 60;

    return (
        <div>
            <p>
                {minutesDisplay}m {secondsDisplay}s
            </p>
        </div>
    );
};

export default Countdown;
