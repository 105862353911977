import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MobileBackButton } from "../../shared/Components/MobileBackButton";
import CustomHeader from "../../shared/CustomHeader/CustomHeader";
import Footer from "../../shared/Footer/Footer";
import { BottomNav } from "../../shared/Navbar/BottomNav";
import PackageContainer from "./PackageContainer";

const Packages = () => {
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top of the page whenever the location changes
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div className="bg-[rgb(248,248,255)]">
            <div className="lg:hidden">
                <MobileBackButton name="Packages" />
            </div>
            <div>
                <div className="hidden lg:block">
                    <CustomHeader title="Packages" />
                </div>
                <div className="px-6 lg:px-0">
                    <PackageContainer />
                </div>
                <div className="hidden lg:block">
                    <Footer />
                </div>
            </div>
            <div className="lg:hidden">
                <div className="h-20"></div>
                <BottomNav />
            </div>
        </div>
    );
};

export default Packages;
