import React from "react";
// Third party packages
import { AiFillCamera } from "react-icons/ai";
import { MdBusinessCenter } from "react-icons/md";
import { TbGridDots } from "react-icons/tb";
import { TiDocumentText } from "react-icons/ti";

export const TopCategories = () => {
    const data = [
        {
            id: 1,
            categories: "Account & Business",
            sub: "263+ Course Available",
            icon: <MdBusinessCenter className="text-[35px]" />,
        },
        {
            id: 2,
            categories: "Certificate Course",
            sub: "350+ Course Available",
            icon: <TiDocumentText className="text-[35px]" />,
        },
        {
            id: 3,
            categories: "Photography",
            sub: "250+ Course Available",
            icon: <AiFillCamera className="text-[35px]" />,
        },
        {
            id: 4,
            categories: "Management",
            sub: "300+ Course Available",
            icon: <TbGridDots className="text-[35px]" />,
        },
    ];
    return (
        <div className="max-w-[1200px] mx-auto mt-[100px] px-[16px] pb-[50px]">
            <h4 className="font-medium font-Inter text-[16px] text-center">Top Categories</h4>
            <h1 className="text-[32px] font-bold font-Inter text-center">Explore Course By Category</h1>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-[20px] mt-[50px]">
                {data?.map(card => {
                    return (
                        <div
                            className="mx-auto bg-white w-full lg:w-[230px] h-[200px] flex flex-col justify-center items-center rounded-[4px] hover:bg-primary cursor-pointer group duration-300"
                            key={card?.id}
                        >
                            <span className="group-hover:text-white">{card.icon}</span>
                            <h3 className="text-[22px] font-semibold group-hover:text-white">{card.categories}</h3>
                            <p className="text-[14px]  text-gray-500 group-hover:text-white">{card?.sub}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
