// Configuration
import React, { Suspense } from "react";

// Components
import { useGetAllBundlesQuery } from "../../../Redux/features/bundle/bundleApi";
import Footer from "../../shared/Footer/Footer";
import { BrandLoader } from "../../shared/Loader/BrandLoader/BrandLoader";
import { BottomNav } from "../../shared/Navbar/BottomNav";
import NavBar from "../../shared/Navbar/NavBar";
import { TopMenu } from "../../shared/Navbar/TopMenu";
import { StudentReviews } from "../StudentReviews/StudentReviews";
import { TopRatedCourse } from "../TopRatedCourse/TopRatedCourse";
import { Banner } from "./Banner";
import { CourseBundle } from "./CourseBundle";
import { CourseCountup } from "./CourseCountup";
import PackagePromo from "./PackagePromo";
import { TopCategories } from "./TopCategories";
import { WhyWeAreBest } from "./WhyWeAreBest";

// css files
import "../../../components/shared/Navbar/TopMenu.css";

export const HomePage = () => {
    const { data, isLoading, isError } = useGetAllBundlesQuery();
    return (
        <div>
            <Suspense fallback={<BrandLoader />}>
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="lg:hidden mov-res bg-gray-600">
                    <TopMenu />
                </div>
                <div className="bg-[rgb(248,248,255)]">
                    <Banner />
                    <TopRatedCourse />
                    <WhyWeAreBest />
                    {data?.data?.length > 0 && <CourseBundle {...{ data, isLoading, isError }} />}
                    <CourseCountup />
                    <PackagePromo />
                    <StudentReviews />
                    <TopCategories />
                </div>

                <div className="lg:hidden">
                    <div className="h-24"></div>
                    <BottomNav />
                </div>

                <div className="hidden lg:block">
                    <Footer />
                </div>
            </Suspense>
        </div>
    );
};
