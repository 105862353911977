import getCookie from "../../../Helper/cookies/getCookie";
import apiSlice from "../../api/apiSlice";

export const contactApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllBundles: builder.query({
            query: () => ({
                url: `/bundle/course/get-all`,
                method: "GET",
            }),
        }),
        getBundleById: builder.query({
            query: id => ({
                url: `/bundle/course/get-bundle-id/${id}`,
                method: "GET",
            }),
        }),
        buyBundle: builder.mutation({
            query: ({ id }) => ({
                url: `/bundle/course/buy-bundle/${id}`,
                method: "POST",
                headers: {
                    authorization: `Bearer ${getCookie("token")}`,
                },
            }),
        }),
        buyBundleByPoint: builder.mutation({
            query: ({ id }) => ({
                url: `/bundle/course/buy-bundle-by-point/${id}`,
                method: "POST",
                headers: {
                    authorization: `Bearer ${getCookie("token")}`,
                },
            }),
        }),
    }),
});

export const { useGetAllBundlesQuery, useGetBundleByIdQuery, useBuyBundleMutation, useBuyBundleByPointMutation } = contactApi;
