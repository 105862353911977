import React from "react";
import { CourseProfileDashboard } from "./CourseProfileDashboard";

export const UserProfile = () => {
    return (
        <div>
            <CourseProfileDashboard />
        </div>
    );
};
