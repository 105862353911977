import React from "react";
import { useNavigate } from "react-router-dom";

const CourseCard = ({ course }) => {
    const navigate = useNavigate();
    const { name, description, image, _id } = course || {};
    return (
        <div
            onClick={() => navigate(`/courses/${_id}`)}
            className="ring-[1px] ring-gray-200 hover:ring-primary duration-300 my-4 flex flex-col md:flex-row hover:shadow-lg"
        >
            <div className="w-full bg-black md:w-[35%] flex items-center justify-center">
                <img className="w-full" src={image} alt="CourseImage" />
            </div>
            <div className="p-2 md:w-[65%]">
                <h1 className="text-[#354895] text-[16px] lg:text-[20px] font-bold font-outfit">{name}</h1>
                <p className="text-[14px] lg:text-[18px] text-gray-500 font-syne mt-[8px] lg:mt-[14px]">
                    {description?.slice(0, 75)}
                    {description?.length > 80 && "..."}
                </p>
            </div>
        </div>
    );
};

export default CourseCard;
