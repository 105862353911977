import React from 'react';

const CourseCard = ({ bundle, paymentMethod }) => {
  const { name, image, price, priceInPoint } = bundle || {};
  console.log(name)
  return (
    <div className="h-[80px] px-[10px] flex justify-between gap-x-[20px] items-center border-b-[1px] border-gray-200">
      <div className="flex items-center gap-x-[4px]">
        <img
          src={image}
          loading="lazy"
          alt="CourseImage"
          className="w-[50px] h-[30px] lg:w-[60px] lg:h-[40px]"
        />
        <p className="text-[14px] font-normal font-Inter lg:text-[16px]">
          {name}
        </p>
      </div>
      <div className="font-Nunito">
        {paymentMethod==="amarPay"? price:priceInPoint}
      </div>
    </div>
  );
};

export default CourseCard;
