import React from "react";
import { useNavigate } from "react-router-dom";

import { TbCurrencyTaka } from "react-icons/tb";

const ShopBundleCard = ({ item, profile, setShowPopup }) => {
    const navigate = useNavigate();

    const canBuy = profile?.coursePackage?.package?.specialBundle;
    // vairable
    const { name, price, image, skillLevel, _id } = item || {};

    const handleBuyNow = () => {
        if (canBuy) {
            navigate(`/bundle/checkout/${_id}`);
        } else {
            setShowPopup(true);
        }
    };

    // REDUX API CALL

    return (
      <div className="h-[430px] w-full lg:w-[260px] mx-auto rounded-[4px] bg-white shadow-[rgba(0,0,0,0.05)_0px_6px_24px_0px,rgba(0,0,0,0.08)_0px_0px_0px_1px] hover:shadow-[rgba(0,0,0,0.16)_0px_10px_36px_0px,rgba(0,0,0,0.06)_0px_0px_0px_1px] duration-300 overflow-hidden">
        <div onClick={() => navigate(`/bundle/${_id}`)} className="relative">
          <img
            className="h-[234px] w-full rounded-tl-[4px] rounded-tr-[4px]"
            src={image}
            alt="courseImage"
            loading="lazi"
          />
          <div className="absolute top-[9px] left-[10px] bg-pink-500 bg-opacity-40 backdrop-blur-xl shadow-md text-white text-[10px] px-2 py-1 font-Nunito font-normal tracking-wider">
            {skillLevel}
          </div>
        </div>
        <div className="content w-full h-[calc(430px-234px)] flex flex-col justify-between p-[10px]">
          <h3
            className="text-[19px] text-left font-bold font-Nunito cursor-pointer hover:text-black text-[#00000092]"
            style={{ zIndex: 1000 }}
            onClick={() => navigate(`/bundle/${_id}`)}
          >
            {name}
          </h3>
          <div className="flex flex-col gap-y-[10px]">
            <div className="flex items-center text-[22px] font-SourceCodePro">
              <span className="flex items-center">
                <TbCurrencyTaka />
                <span className="text-gray-600 font-bold">{price}</span>
              </span>
            </div>
            <div className="w-full">
              <button
                // className="special_profile_button"
                className="w-full h-[44px] special_profile_button font-Nunito text-[20px] font-medium"
                onClick={handleBuyNow}
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ShopBundleCard;
