import About from "../../components/pages/About/About";
import Login from "../../components/pages/Authentications/Login";
import { OTP } from "../../components/pages/Authentications/OTP/OTP";
import Signup from "../../components/pages/Authentications/SignUp";
import Contact from "../../components/pages/Contact/Contact";
import { CourseAccess } from "../../components/pages/Courses/CourseAccess/CourseAccess";
import { CourseFilterForMobile } from "../../components/pages/Courses/LandingPage/CourseFilterForMobile/CourseFilterForMobile";
import { CourseLandingPage } from "../../components/pages/Courses/LandingPage/CourseLandingPage";
import { SingleCoursePage } from "../../components/pages/Courses/SingleCoursePage/SingleCoursePage";
import { HomePage } from "../../components/pages/Home/HomePage";
import { Privacy } from "../../components/pages/Privacy/Privacy";
import Packages from "../../components/pages/packages/Packages";

const courseRoute = [
    {
        id: 1,
        path: "/",
        element: <HomePage />,
    },
    {
        id: 2,
        path: "/courses",
        element: <CourseLandingPage />,
    },
    {
        id: 3,
        path: "/courses/:id",
        element: <SingleCoursePage />,
    },
    {
        id: 4,
        path: "/login",
        element: <Login />,
    },
    {
        id: 5,
        path: "/signup",
        element: <Signup />,
    },
    {
        id: 6,
        path: "/about",
        element: <About />,
    },
    {
        id: 7,
        path: "/contact",
        element: <Contact />,
    },
    {
        id: 8,
        path: "/privacy-policy",
        element: <Privacy />,
    },
    {
        id: 9,
        path: "/otp",
        element: <OTP />,
    },
    {
        id: 10,
        path: "/courseFilter",
        element: <CourseFilterForMobile />,
    },
    {
        id: 11,
        path: "/courseAccess/:id",
        element: <CourseAccess />,
    },
    {
        id: 12,
        path: "/membership",
        element: <Packages />,
    },
];

export default courseRoute;
