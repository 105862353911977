// configuration
import React from "react";

import { useState } from "react";
// components
import { MobileBackButton } from "../../shared/Components/MobileBackButton";
import CustomHeader from "../../shared/CustomHeader/CustomHeader";
import {
  useGetBundleByIdQuery,
} from "../../../Redux/features/bundle/bundleApi";
import { ServerErrorMessage } from "../../ui/error/ServerErrorMessage";
import { LineWaveLoader } from "../../shared/Loader/lineWaveLoader/LineWaveLoader";
import { SingleCourseLeft } from "./SingleCourseLeft";
import { SingleCourseInfoCard } from "./SingleCourseInfoCard";
import { useParams } from "react-router-dom";
import { useGetProfileDetailsWIthAuthQuery } from "../../../Redux/features/userInfo/userApi";
import GoPremium from "../../shared/Packages/GoPremium";
const message ="You need to Update you package to buy a bundle. Please go and read our offers in packages";
export const BundleCart = () => {
  const [showPopup, setShowPopup] = useState(false);
  const {id} = useParams()
  const { data, isLoading, isError } = useGetBundleByIdQuery(id);
  const { data: profile, isLoading: profLoading } =
    useGetProfileDetailsWIthAuthQuery();

  let content;
  if (isLoading || profLoading) {
    content = (
      <div className="min-h-[350px]">
        <LineWaveLoader />
      </div>
    );
  } else if (!isLoading && isError) {
    content = (
      <div className="w-full flex justify-center items-center min-h-[300px]">
        <ServerErrorMessage />
      </div>
    );
  } else if (!isLoading && data && profile) {
    content = (
      <div className="w-full lg:w-[1200px]  2xl:w-[1400px] mx-auto h-auto flex lg:flex-row flex-col gap-y-[20px] pt-[44px] px-[26px]">
        <div className=" w-full lg:max-w-[660px] 2xl:w-[850px] mx-auto">
          <SingleCourseLeft {...{ bundle: data?.data }} />
        </div>
        <div className="sticky top-[44px] flex-1 mx-auto max-w-[400px]">
          <SingleCourseInfoCard {...{ bundle: data?.data, profile, setShowPopup }} />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="pb-[50px]">
        <div className="hidden lg:block">
          <CustomHeader title="Bundle" />
        </div>
        <div className=" lg:hidden">
          <MobileBackButton name="Course Info" />
        </div>
        <section>{content}</section>
      </div>
      {showPopup && <GoPremium {...{ message, setShowPopup }} />}
    </>
  );
};
