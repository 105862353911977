import React, { useEffect } from "react";
import useDocumentTitle from "../../../assets/utilities/useDocumentTitle";
import CustomHeader from "../../shared/CustomHeader/CustomHeader";
import AboutHeaderBanner from "./AboutHeaderBanner";
import AboutOurMission from "./AboutOurMission";
import AboutUsStory from "./AboutUsStory";
import AboutUsOurHistory from "./AboutUsOurHistory";
import Footer from "../../shared/Footer/Footer";

const About = () => {
  useDocumentTitle("Shongshari | About");
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="hidden md:block">
        <CustomHeader title={"About Us"} />
        <AboutHeaderBanner />
        <AboutOurMission />
        <AboutUsStory />
        <AboutUsOurHistory />
        <Footer />
      </div>
      <div className="md:hidden"></div>
    </>
  );
};
export default About;
