import React, { useState } from "react";
import { QuizAnsCard } from "./QuizAnsCard";

export const QuizQuesCard = ({ question, quesIndex, setAnswers, answers }) => {
    const [selectedAns, setSelectedAnswer] = useState(null);
    return (
        <div className="mb-[30px]">
            <h3 className="text-center text-[18px] text-gray-600 font-medium font-outfit">{question?.question}</h3>
            <div className="flex flex-col gap-y-[8px]">
                {question?.options?.map((item, index) => {
                    return (
                        <QuizAnsCard
                            key={index}
                            item={item}
                            quesIndex={quesIndex}
                            setSelectedAnswer={setSelectedAnswer}
                            selectedAns={selectedAns}
                            setAnswers={setAnswers}
                            answers={answers}
                        />
                    );
                })}
            </div>
        </div>
    );
};
