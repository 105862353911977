import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadNextModule, loadNextSection, loadNextVideo } from "../../../../../Redux/features/Course/CourseOutline/courseOutlineSlice";
import { useContentStatusUpdateMutation } from "../../../../../Redux/features/Course/courseApi";
import { nextVideoDecisionMaker } from "../../../../../assets/utilities/NextVideoDesicionMaker/nextVideoDecisionMaker";
import { PlayerTabs } from "./PlayerTabs";
import { Reacthtml5Player } from "./Reacthtml5Player";

export const PlayerContainer = ({ link, videoName, currentVideoID, sections }) => {
    const [contentStatusUpdate] = useContentStatusUpdateMutation();
    const currentStatus = useSelector(state => state.persistedReducer.courseOutline);
    const dispatch = useDispatch();

    let isPlaying = false;
    if (link) {
        isPlaying = true;
    }

    // Logic 1 : current video status will be update when function called
    // Logic 2 : Take a decision in our state that the next content / next module / next section will be unlocked
    const handlePlay = async () => {
        contentStatusUpdate(currentVideoID);
        const result = nextVideoDecisionMaker(sections, currentStatus);

        if (result === "nextContent") {
            dispatch(loadNextVideo());
        } else if (result === "nextModule") {
            dispatch(loadNextModule());
        } else if (result === "nextSection") {
            dispatch(loadNextSection());
        }
    };

    return (
        <div className="w-full">
            <div className="min-h-[200px] border-[4px] border-primary">
                <Reacthtml5Player {...{ handlePlay, link, isPlaying }} />
            </div>
            <div className="h-auto mt-[10px] w-full flex justify-start items-center text-[18px] font-Inter font-medium">{videoName}</div>
            <div>
                <PlayerTabs />
            </div>
        </div>
    );
};
