import React from "react";

// Third party packages
import { BsArrowRight } from "react-icons/bs";

// Components
import { useNavigate } from "react-router-dom";
import { useGetAllCoursesQuery } from "../../../Redux/features/Course/courseApi";
import CourseSkeletonLoader from "../../shared/Loader/CardSkeletons/CourseSkeletonLoader";
import { CourseBundleCard } from "../../shared/cards/courses/CourseBundleCard";
import { ServerErrorMessage } from "../../ui/error/ServerErrorMessage";

export const TopRatedCourse = () => {
    const navigate = useNavigate();
    // get data by filtering
    const { data, isLoading, isError } = useGetAllCoursesQuery({
        page: 1,
        limit: 4,
        categroyId: "",
        typeId: "",
        skill: "",
    });
    const { courses, total } = data || {};
    const loaderArr = [1, 2, 3, 4];
    let content;
    if (isLoading) {
        content = (
            <div className="max-w-[1200px] mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-[50px] px-[16px] gap-[20px]">
                {loaderArr?.map(item => {
                    return <CourseSkeletonLoader key={item} />;
                })}
            </div>
        );
    } else if (!isLoading && isError) {
        content = <ServerErrorMessage />;
    } else if (!isLoading && courses?.length === 0) {
        content = <div className="w-full h-[350px] flex justify-center items-center font-Inter text-gray-500 text-[20px]">No Course Found!</div>;
    } else if (!isLoading && courses?.length > 0) {
        content = (
            <div className="max-w-[1200px] mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-[50px] px-[16px] gap-[20px]">
                {courses?.map(course => {
                    return <CourseBundleCard course={course} key={course._id} />;
                })}
            </div>
        );
    }
    return (
        <section className="max-w-[1200px] mx-auto mt-[100px]">
            <div className="flex justify-between items-center px-[16px]">
                <h1 className="text-[16px] lg:text-[24px] font-Poppins font-semibold">Top Rated Course</h1>
                {total > 4 && (
                    <button className="flex items-center group" onClick={() => navigate("/courses")}>
                        See all{" "}
                        <span>
                            <BsArrowRight className="text-[14px] ml-[4px] group-hover:ml-[12px] duration-300" />
                        </span>
                    </button>
                )}
            </div>
            <div>{content}</div>
        </section>
    );
};
