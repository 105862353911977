import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import animatedPlayButton from "../../../../../assets/lottie/animatedPlayButton.json";
import CourseVideoIntro from "./CourseVideoIntro";

export const LeftSideHeader = ({ course }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { name, description, image, video } = course || {};
    const animationContainer = useRef(null);

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg", // Choose the appropriate renderer (svg, canvas, html)
            loop: true,
            autoplay: true,
            animationData: animatedPlayButton,
        });

        return () => {
            // Clean up the animation when the component unmounts
            anim.destroy();
        };
    }, []);

    const modalControll = () => {
        setModalOpen(!modalOpen);
    };
    return (
        <div>
            <h1 className="text-[#354895] text-[24px] lg:text-[34px] font-bold font-outfit">{name}</h1>
            <p className="text-[14px] lg:text-[20px] text-gray-500 font-syne mt-[8px] lg:mt-[14px]">{description}</p>
            <div className="w-full lg:max-w-[666px] lg:max-h-[371px] relative">
                <img src={image} alt="CourseImage" className="mt-[18px] w-full rounded-[6px] lg:max-w-[666px] lg:max-h-[371px]" loading="lazy" />
                {video && <div ref={animationContainer} className="absolute w-[150px] inset-0 m-auto" onClick={modalControll}></div>}
            </div>
            {modalOpen && <CourseVideoIntro modalControll={modalControll} video={video} />}
        </div>
    );
};
