import React from "react";

const CourseSkeletonLoader = () => {
    return (
        <div class="h-[350px] lg:h-[330px] w-[260px] ring-1 ring-gray-200 rounded-[4px]">
            <div class="h-[195px] lg:h-[165px] bg-gray-100 animate-pulse"></div>
            <div class="p-[10px]">
                <div class="h-[12px] w-3/4 mt-[15px] bg-gray-100"></div>
                <div class="h-[12px] w-3/4 mt-[15px] bg-gray-100"></div>
            </div>
        </div>
    );
};

export default CourseSkeletonLoader;
