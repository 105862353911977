import { Tabs } from "antd";
import React from "react";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { QuizContainer } from "../Quiz/QuizContainer";

export const PlayerTabs = () => {
    const onChange = key => {
        console.log(key);
    };

    const copyrightContent = (
        <div className="text-[12px] text-gray-400 flex flex-col gap-y-[15px]">
            <p>
                All content and materials provided on this website, including but not limited to text, graphics, logos, button icons, images, audio
                clips, digital downloads, data compilations, and software, are the intellectual property of Shongshari.com or its content suppliers
                and are protected by international copyright laws.
            </p>
            <p>
                {" "}
                The compilation of all content on this site is the exclusive property of Shongshari.com and is protected by copyright laws. Any
                unauthorized use, including reproduction, modification, distribution, transmission, display, or performance of the content on this
                website is strictly prohibited.
            </p>
            <p>
                The courses and educational materials available on this website are intended for the personal and non-commercial use of our users. You
                may not reproduce, distribute, modify, transmit, perform, display, or create derivative works from any of the course materials without
                prior written consent from Shongshari.com or the respective copyright owner.
            </p>
            <p>
                By accessing and using this website, you acknowledge and agree that all copyrights, trademarks, and other intellectual property rights
                in the content and materials provided on this website are owned by Shongshari.com or its licensors.
            </p>
            <p>
                We take copyright infringement seriously and will enforce our rights to the fullest extent of the law. If you believe that any content
                on this website infringes upon your copyright, please contact us immediately with the necessary information to support your claim.
            </p>
            <p>
                Unauthorized use of the content and materials on this website may violate copyright laws, trademark laws, privacy laws, and other
                applicable laws and regulations. Legal action may be taken against individuals or entities found to be in violation of these rights.
            </p>
            <p>Thank you for respecting our intellectual property rights and for using our website in compliance with these copyright terms.</p>
        </div>
    );

    const copyright = (
        <div className="flex items-center gap-x-[5px]">
            <AiOutlineCopyrightCircle />
            <span>Copyright</span>
        </div>
    );
    const items = [
        {
            key: "1",
            label: copyright,
            children: copyrightContent,
        },
        {
            key: "2",
            label: `Quiz`,
            children: <QuizContainer />,
        },
    ];

    return (
        <div className="mt-[15px]">
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
    );
};
