import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAttempted, setIsPass, setScore, setTotal } from "../../../../../../Redux/features/Course/CourseQuiz/courseMarkshitSlice";
import { setQuizStarted } from "../../../../../../Redux/features/Course/CourseQuiz/courseQuizSlice";
import { useCheckAnswerMutation, useStartQuizMutation } from "../../../../../../Redux/features/Course/courseApi";
import Countdown from "../../../../../shared/CountDown/Countdown";
import { AnswerPaper } from "./AnswerPaper";

export const AnswerSheet = () => {
    const [answers, setAnswers] = useState([]);
    const [questions, setQuestions] = useState(null);
    const [totalTime, setTotaltime] = useState(0);
    const quizID = useSelector(state => state.persistedReducer.quiz.currentQuizID);
    const dispatch = useDispatch();

    const [startQuiz, { data }] = useStartQuizMutation(quizID || "");
    const [checkAnswer, { isLoading: checkLoading, data: checkedResponse }] = useCheckAnswerMutation();

    useEffect(() => {
        startQuiz(quizID);
    }, [quizID, startQuiz]);

    useEffect(() => {
        const { userQuiz } = data || {};
        const { quiz } = userQuiz || {};
        setQuestions(quiz?.question);
        setTotaltime(quiz?.timeCount);
    }, [data]);

    useEffect(() => {
        if (checkedResponse) {
            console.log(checkedResponse);
            const { score, total, userQuiz } = checkedResponse || {};
            const { isPassed } = userQuiz || {};
            dispatch(setScore(score));
            dispatch(setTotal(total));
            dispatch(setIsPass(isPassed));
            dispatch(setIsAttempted(true));
            dispatch(setQuizStarted(false));
        }
    }, [checkedResponse, dispatch]);

    // function declaration
    const quizEnd = () => {
        // dispatch(setQuizEnd());
        checkAnswer({
            data: {
                answers: answers,
            },
            quizeID: quizID,
        });
    };

    return (
        <>
            <div className="flex justify-between items-center w-full mt-[25px] h-auto ring-1 ring-gray-100 rounded-[4px] p-[15px]">
                <h3>Quiz Name</h3>
                <div className="flex flex-col">
                    <button>End Quiz</button>
                    {totalTime > 0 && <Countdown minutes={totalTime} seconds={0} quizEnd={quizEnd} />}
                </div>
            </div>
            <div className="w-full mt-[25px] h-auto ring-1 ring-gray-100 rounded-[4px] p-[20px]">
                {questions !== null && <AnswerPaper questions={questions} setAnswers={setAnswers} answers={answers} />}
            </div>
            <section className="mt-[15px]">
                <div className="w-full flex justify-center pb-[25px]">
                    <button
                        className="bg-green-600 hover:bg-green-500 duration-300 px-4 py-1 rounded-[4px] flex items-center gap-x-[5px] text-white"
                        onClick={quizEnd}
                    >
                        {checkLoading ? "Checking" : "Quiz End"}
                    </button>
                </div>
            </section>
        </>
    );
};
