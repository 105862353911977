// Configuration
import React from "react";

// Third party packages
import { AiOutlinePlayCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentVideo } from "../../../../../Redux/features/Course/CourseAccess/coursesAccessSlice";

export const Contents = ({ contents, lastUnlockedContent }) => {
    const { currentVideo } = useSelector(state => state.persistedReducer?.courseAccess) || {};

    const { _id: currentID } = currentVideo || {};
    const dispatch = useDispatch();

    const handleCurrentVideo = video => {
        dispatch(setCurrentVideo(video));
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="flex flex-col gap-y-[12px]">
            {contents?.map((content, index) => {
                return (
                    <button
                        className={`flex items-center justify-start py-[4px] gap-x-[6px]  px-[6px] rounded-[4px]  ${
                            currentID === content?._id ? "bg-gray-100" : "bg-transparent"
                        } ${index > lastUnlockedContent ? "text-[#00000040] bg-[#FAFAFA]" : "hover:bg-gray-100"} ${
                            index > lastUnlockedContent ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() => handleCurrentVideo(content)}
                        key={content?._id}
                        disabled={index > lastUnlockedContent && true}
                    >
                        <AiOutlinePlayCircle className="text-[20px]" />
                        <div className="flex flex-col font-outfit">
                            <h4 className="text-[12px] text-start">{content?.name || "Not added"}</h4>
                            <span className="text-[8px] text-start">20 Minutes</span>
                        </div>
                    </button>
                );
            })}
        </div>
    );
};
