import React from "react";
import { QuizQuesCard } from "./card/QuizQuesCard";

export const AnswerPaper = ({ questions, setAnswers, answers }) => {
    return (
        <div>
            {questions?.map((question, index) => {
                return <QuizQuesCard key={question?._id} question={question} quesIndex={index} setAnswers={setAnswers} answers={answers} />;
            })}
        </div>
    );
};
