import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentQuizID: null,
    isQuizStart: false,
    answers: [],
};

const courseQuizSlice = createSlice({
    name: "courseQuiz",
    initialState,
    reducers: {
        setCurrenntQuizID: (state = initialState, action) => {
            if (state.currentQuizID !== action.payload) {
                state.isQuizStart = false;
                state.currentQuizID = action.payload;
            } else {
                state.currentQuizID = action.payload;
            }
        },
        setQuizStarted: (state = initialState, action) => {
            state.isQuizStart = action.payload;
        },
        setQuizEnd: (state = initialState, action) => {
            state.isQuizStart = false;
        },
        resetQuizSlice: (state = initialState, action) => {
            state.currentQuizID = null;
            state.isQuizStart = false;
            state.answers = [];
        },
    },
});

export default courseQuizSlice.reducer;
export const { setCurrenntQuizID, setQuizStarted, setQuizEnd, resetQuizSlice } = courseQuizSlice.actions;
