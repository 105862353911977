/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Third party packages
import { MdSecurity } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useBuyBundleByPointMutation, useBuyBundleMutation } from "../../../../Redux/features/bundle/bundleApi";
import { OvalLoader } from "../../../shared/Loader/OvalLoader/OvalLoader";
import Error from "../../../ui/error/Error";
import { SuccessSnackBar } from "../../../ui/error/snackBar/SuccessSnackBar";
import CourseCard from "./courseCard";

export const CourseCheckoutRightSide = ({ bundle }) => {
    const [customError, setCustomError] = useState("");
    const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);

    const navigate = useNavigate();
    const { price, _id, priceInPoint } = bundle || {};
    const { paymentMethod } = useSelector(state => state.persistedReducer?.paymentMethod) || {};

    const [buyCourse, { isLoading, isSuccess, data, error, isError }] = useBuyBundleMutation();
    const [buyCourseWithPoint, { isSuccess: pointResponse, isLoading: pointLoading, error: pointError }] = useBuyBundleByPointMutation();

    //   const finalPrice = price - ((price * courseDiscount) / 100 || 0);
    const finalPoint = priceInPoint;

    // order placed
    const handleOrder = () => {
        // body create
        if (paymentMethod === "amarPay") {
            buyCourse({
                id: _id,
            });
            return;
        } else if (paymentMethod === "point") {
            buyCourseWithPoint({
                id: _id,
            });
            return;
        }
    };

    useEffect(() => {
        if (data?.redirect) {
            window.location.replace(data?.data);
        } else if (data && !data?.redirect) {
            setSuccessSnackBarOpen(true);
            setTimeout(() => navigate("/"), 500);
        }
    }, [data]);

    useEffect(() => {
        if (error && error.status !== 301 && error?.data?.message) {
            setCustomError(error?.data?.message);
        }
    }, [error]);

    useEffect(() => {
        if (pointResponse) {
            setSuccessSnackBarOpen(true);
            setTimeout(() => navigate("/payment_Success"), 500);
        }
    }, [pointResponse, navigate]);

    useEffect(() => {
        if (pointError) {
            setCustomError("You have not enough point");
        }
    }, [pointError]);

    let content;
    if (paymentMethod === "amarPay") {
        content = (
            <>
                <CourseCard {...{ bundle, paymentMethod }} />
                <div className="h-[60px] px-[10px] flex justify-between items-center">
                    <span className="font-Nunito font-semibold text-[18px]">Bundle Price(BDT)</span>
                    <span className="font-medium">{price}</span>
                </div>
            </>
        );
    } else if (paymentMethod === "point") {
        content = (
            <>
                <CourseCard {...{ bundle, paymentMethod }} />

                <div className="h-[60px] px-[10px] flex justify-between items-center">
                    <span className="font-Nunito font-semibold text-[18px]">Bundle Price(POINT)</span>
                    <span className="font-medium">{finalPoint}</span>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="mt-[30px] w-full shadow-[0px_2px_6px_rgba(0,0,0,0.14)] p-[20px] rounded-[4px] bg-white">
                <h3 className="text-[20px] font-fira font-semibold text-black">Your order at a glance</h3>
                <div className="h-[60px] mt-[20px] rounded-[4px] w-full bg-slate-100 shadow-md flex justify-between items-center px-[10px]">
                    <p className="text-[16px] font-semibold font-Inter">Product</p>
                    <p className="text-[16px] font-semibold font-Inter">Total</p>
                </div>
                {content}

                <div className="flex items-center justify-center gap-x-[8px]">
                    <MdSecurity className="text-[14px] text-green-500" />
                    <p className="font-Inter text-[14px] text-green-500">Payment Secured</p>
                </div>
                {customError && <Error message={customError} />}
                <button
                    className={`w-full mt-[20px] lg:mt-[30px] h-[40px] rounded-[4px] flex justify-center items-center ${
                        isSuccess ? "bg-green-400" : "bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)]"
                    } ${
                        !isSuccess || (!isError && "hover:bg-[linear-gradient(166deg,rgb(255,0,128)_0%,rgb(148,45,255)_100%)]")
                    } text-white duration-300 ${isError && "cursor-not-allowed"}`}
                    onClick={handleOrder}
                    disabled={isSuccess || isError}
                >
                    {isLoading || pointLoading ? (
                        <OvalLoader height={30} width={30} color="#FFFFFF" />
                    ) : isSuccess ? (
                        "Purchased"
                    ) : (
                        <span>Purchase Now</span>
                    )}
                </button>
            </div>
            {successSnackBarOpen && (
                <SuccessSnackBar
                    {...{
                        successSnackBarOpen,
                        setSuccessSnackBarOpen,
                        message: "Successful",
                    }}
                />
            )}
        </>
    );
};
