import React from "react";
import { PaymentMethodForCourse } from "./PaymentMethodForCourse";

export const CourseCheckoutLeftSide = () => {
    return (
        <div>
            {/* <BillingInfoBoxForCourse /> */}
            <PaymentMethodForCourse />
        </div>
    );
};
