import React,{useState} from "react";
import { useGetAllBundlesQuery } from "../../../Redux/features/bundle/bundleApi";
import ShopBundleCard from "./ShopBundleCard";
import { LineWaveLoader } from "../../shared/Loader/lineWaveLoader/LineWaveLoader";
import { ServerErrorMessage } from "../../ui/error/ServerErrorMessage";
import { useGetProfileDetailsWIthAuthQuery } from "../../../Redux/features/userInfo/userApi";
import GoPremium from "../../shared/Packages/GoPremium";

const message = "You need to Update you package to buy a bundle. Please buy \"Bundle\" package to buy a bundle"
const BundleContainer = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data, isLoading, error } = useGetAllBundlesQuery();
  const { data: profile, isLoading:profLoading } = useGetProfileDetailsWIthAuthQuery();
  return (
    <>
      <div className="max-w-[1200px] mx-auto">
        <h1 className="text-4xl font-bold py-8">Special Bundles for you</h1>
        {(isLoading || profLoading) && (
          <div className="my-16">
            <LineWaveLoader title="Packages" />
          </div>
        )}

        {error && (
          <div className="flex items-center justify-center my-16">
            <ServerErrorMessage />
          </div>
        )}
        {data?.data?.length === 0 && (
          <div className="text-center text-3xl font-bold text-gray-400 my-16">
            No Bundle Found
          </div>
        )}
        {data?.data?.length !== 0 && profile && (
          <div className="grid md:grid-cols-3 gap-4 px-2 md:px-0">
            {data?.data?.map((item) => (
              <ShopBundleCard {...{ item, profile, setShowPopup }} key={item._id} />
            ))}
          </div>
        )}
      </div>
      {showPopup && <GoPremium {...{ setShowPopup, message }} />}
    </>
  );
};

export default BundleContainer;
