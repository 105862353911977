import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useGetHighlightedInformationsQuery } from "../../../Redux/features/Course/courseApi";
import { ServerErrorMessage } from "../../ui/error/ServerErrorMessage";

export const CourseCountup = () => {
    const [counterOn, setCounterOn] = useState(false);
    const { isLoading, isError, data: info } = useGetHighlightedInformationsQuery();
    const { totalReview, totalSelledCourse, totalStudent } = info || {};
    const data = [
        {
            id: 1,
            count: totalStudent,
            title: "Happy Student",
        },
        {
            id: 2,
            count: totalSelledCourse,
            title: "Service sell",
        },
        {
            id: 3,
            count: totalReview,
            title: "Total Reviews",
        },
    ];

    let content;
    if (isLoading) {
        return;
    } else if (!isLoading && isError) {
        content = (
            <div>
                <ServerErrorMessage />
            </div>
        );
    } else if (!isLoading && !isError && info) {
        content = (
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                <div className="w-full h-auto lg:h-[250px] bg-[linear-gradient(166deg,rgb(242,40,118)_0%,rgb(148,45,217)_100%)] mt-[100px] py-[10px] lg:py-0">
                    <div className="max-w-[1200px] mx-auto grid grid-cols-1 lg:grid-cols-3 gap-x-[20px] h-full">
                        {data?.map(card => {
                            return (
                                <div className="flex flex-col justify-center mx-auto items-center my-[50px]" key={card?.id}>
                                    {counterOn && (
                                        <CountUp
                                            className="text-[40px] lg:text-[48px] font-semibold text-white"
                                            end={card?.count}
                                            delay={0.5}
                                            duration={3}
                                        />
                                    )}
                                    <p className="text-white/70 text-[18px] lg:text-[24px]">{card?.title}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ScrollTrigger>
        );
    }

    return content;
};
