import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
};

const courseCheckoutSlice = createSlice({
    name: "courseCheckout",
    initialState,
    reducers: {
        setInitialCourseCheckoutData: (state = initialState, action) => {
            state.firstName = action.payload.firstName || null;
            state.lastName = action.payload.lastName || null;
            state.phone = action.payload.phone || null;
            state.email = action.payload.email || null;
        },
        setFirstNameForCheckout: (state = initialState, action) => {
            state.firstName = action.payload;
        },
        setLastNameForCheckout: (state = initialState, action) => {
            state.lastName = action.payload;
        },
        setPhoneNumberForCheckout: (state = initialState, action) => {
            state.phone = action.payload;
        },
    },
});

export default courseCheckoutSlice.reducer;
export const { setInitialCourseCheckoutData, setFirstNameForCheckout, setLastNameForCheckout, setPhoneNumberForCheckout } =
    courseCheckoutSlice.actions;
