import React, { useEffect, useState } from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import VarientAnimation from "../../../../assets/utilities/Animation/VarientAnimation";

export const CourseBundleCard = ({ course }) => {
    const navigate = useNavigate();
    const [trainers, setTrainers] = useState([]);
    const { name, image, price, oldPrice, instructor, _id } = course || {};

    useEffect(() => {
        let trainer = [];
        instructor?.map(item => {
            trainer.push(item?.name);
            return false;
        });

        setTrainers(trainer);
    }, [instructor]);
    return (
        <VarientAnimation direction="up" delay={0.3}>
            <abbr title={course?.description} className="no-underline">
                <div
                    className="w-full lg:w-[260px] ring-1 ring-gray-200 duration-200 hover:ring-green-600 h-[350px] lg:h-[330px] rounded-[4px] mx-auto cursor-pointer overflow-hidden"
                    onClick={() => navigate(`/courses/${_id}`)}
                >
                    <img src={image} alt="banner" className="h-[195px] lg:h-[165px] w-full" />
                    <div className="p-[10px] flex flex-col h-[calc(260px-110px)] justify-between">
                        <div>
                            <h1 className="text-[18px] font-semibold font-Inter">{name}</h1>

                            <p className="text-[14px] mt-[6px] text-[rgb(107,114,128)]">{trainers?.join(" ,")}</p>
                        </div>
                        <div className="flex items-start gap-x-[20px]">
                            <span className="flex items-center justify-center">
                                <TbCurrencyTaka className="text-gray-800" />
                                {price}
                            </span>
                            <del className="flex items-center justify-center">
                                <TbCurrencyTaka className="text-gray-800" />
                                {oldPrice}
                            </del>
                        </div>
                    </div>
                </div>
            </abbr>
        </VarientAnimation>
    );
};
