import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import apiSlice from "../api/apiSlice";
import coursesAccessSlice from "../features/Course/CourseAccess/coursesAccessSlice";
import courseOutlineSlice from "../features/Course/CourseOutline/courseOutlineSlice";
import courseMarkshitSlice from "../features/Course/CourseQuiz/courseMarkshitSlice";
import courseQuizSlice from "../features/Course/CourseQuiz/courseQuizSlice";
import PaymentMethodSlice from "../features/Course/PaymentMethodSlice";
import courseCheckoutSlice from "../features/Course/courseCheckoutSlice";
import courseFilterSlice from "../features/Course/courseFilterSlice";
import userInfo from "../features/userInfo/userInfo";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const reducer = combineReducers({
    userInfo: userInfo,
    courseFilter: courseFilterSlice,
    courseAccess: coursesAccessSlice,
    courseCheckout: courseCheckoutSlice,
    paymentMethod: PaymentMethodSlice,
    quiz: courseQuizSlice,
    courseMarkSheet: courseMarkshitSlice,
    courseOutline: courseOutlineSlice,
});
const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        persistedReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

export default store;
