import { Divider } from "antd";
import React, { useEffect } from "react";
import { FaPlay } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import useBeforeUnload from "../../../../../Helper/hooks/checkLoggerPersestency/useBeforeUnload";
import { setIsAttempted, setIsPass, setScore, setTotal } from "../../../../../Redux/features/Course/CourseQuiz/courseMarkshitSlice";
import { setQuizStarted } from "../../../../../Redux/features/Course/CourseQuiz/courseQuizSlice";
import { useGetQuizInfoQuery } from "../../../../../Redux/features/Course/courseApi";
import { LineWaveLoader } from "../../../../shared/Loader/lineWaveLoader/LineWaveLoader";
import { AnswerSheet } from "./AnswerSheet/AnswerSheet";
import { Marksheet } from "./Marksheet/Marksheet";
import { QuizHeader } from "./QuizHeader";

export const QuizContainer = () => {
    const dispatch = useDispatch();
    useBeforeUnload();

    const isAttempted = useSelector(state => state.persistedReducer?.courseMarkSheet?.isAttemted);

    // state
    const { isQuizStart, currentQuizID } = useSelector(state => state.persistedReducer.quiz) || {};

    //Redux API
    const { isLoading, data, isSuccess, isFetching, isError } = useGetQuizInfoQuery(currentQuizID);
    const { userQuiz, quiz } = data || {};
    useEffect(() => {
        const { isAttempted, userQuiz, quiz } = data || {};
        const { isPassed, score } = userQuiz || {};
        const { question } = quiz || {};
        dispatch(setIsAttempted(isAttempted));
        dispatch(setScore(score));
        dispatch(setTotal(question?.length));
        dispatch(setIsPass(isPassed));
    }, [data, dispatch]);

    //function declaration
    const quizControll = () => {
        dispatch(setQuizStarted(true));
    };

    let content;
    if (isLoading || isFetching) {
        content = (
            <div>
                <LineWaveLoader />
            </div>
        );
    } else if (!isLoading && isError) {
        content = <p>Something is Wrong..</p>;
    } else if (!isLoading && !isFetching && isSuccess) {
        content = (
            <>
                <div className="h-auto w-full ring-1 ring-gray-100 rounded-[4px]">
                    {isAttempted && <Marksheet {...{ userQuiz, quiz }} />}
                    {!isAttempted && <QuizHeader data={data} />}
                    {!isQuizStart && !isAttempted && (
                        <section>
                            <Divider />
                            <div className="w-full flex justify-center pb-[25px]">
                                <button
                                    className="bg-green-600 hover:bg-green-500 duration-300 px-4 py-1 rounded-[4px] flex items-center gap-x-[5px] text-white"
                                    onClick={quizControll}
                                >
                                    <FaPlay /> Start
                                </button>
                            </div>
                        </section>
                    )}
                </div>

                {isQuizStart && !isAttempted && <AnswerSheet />}
            </>
        );
    }

    return content;
};
