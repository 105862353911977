import React from "react";
import { LeftSideHeader } from "./leftSide/LeftSideHeader";
import CourseCard from "./CourseCard";
import {Faq} from "./leftSide/faq/Faq"
import { CourseInfo } from "./leftSide/CourseInfo/CourseInfo";

export const SingleCourseLeft = ({ bundle }) => {
    return (
      <div className="relative">
        <LeftSideHeader bundle={bundle} />
        <div>
          <CourseInfo {...{ bundle }} />
          <h1 className="text-[#354895] text-[24px] lg:text-[34px] font-bold font-outfit">
            This Bundle Includes the following courses
          </h1>
          <div>
            {bundle?.courses?.map((item) => (
              <CourseCard {...{ course: item }} />
            ))}
          </div>
          <Faq {...{ bundle }} />
        </div>
      </div>
    );
};
