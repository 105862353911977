import React, { useEffect, useState } from "react";

// Third party packages
import { MdSecurity } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useBuyCourseMutation, useBuyCourseWithPointMutation } from "../../../../Redux/features/Course/courseApi";
import { useGetProfileDetailsWIthAuthQuery, useUpdatePersonalDetailsMutation } from "../../../../Redux/features/userInfo/userApi";
import { OvalLoader } from "../../../shared/Loader/OvalLoader/OvalLoader";
import Error from "../../../ui/error/Error";
import { SuccessSnackBar } from "../../../ui/error/snackBar/SuccessSnackBar";

export const CourseCheckoutRightSide = ({ course }) => {
    const [customError, setCustomError] = useState("");
    const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
    const [finalPrice, setFinalPrice] = useState(0);
    const [finalPoint, setFinalPoint] = useState(0);
    const [feeOff, setFeeOff] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [courseFee, setCourseFee] = useState(0);
    const [courseFeeInPoint, setCourseFeeInPoint] = useState(0);

    const navigate = useNavigate();
    const { name, image, price, _id, priceInPoint } = course || {};
    const checkoutDetailes = useSelector(state => state.persistedReducer?.courseCheckout);
    const { paymentMethod } = useSelector(state => state.persistedReducer?.paymentMethod) || {};

    const [buyCourse, { isLoading, isSuccess, data, error, isError }] = useBuyCourseMutation();
    const [buyCourseWithPoint, { isSuccess: pointResponse, isLoading: pointLoading, error: pointError }] = useBuyCourseWithPointMutation();
    const [updatePersonalDetails] = useUpdatePersonalDetailsMutation();
    const { data: myProfile } = useGetProfileDetailsWIthAuthQuery();

    // order placed
    const handleOrder = () => {
        const detailes = { ...checkoutDetailes };
        delete detailes.email;

        // update personal data in our db
        updatePersonalDetails(detailes);

        // body create
        let body;
        if (paymentMethod === "amarPay") {
            body = {
                id: _id,
                data: {
                    amount: finalPrice,
                },
            };
            buyCourse(body);
            return;
        } else if (paymentMethod === "point") {
            body = {
                id: _id,
                data: {
                    priceInPoint: finalPoint,
                },
            };
            buyCourseWithPoint(body);
            return;
        }
    };

    useEffect(() => {
        if (data?.redirect) {
            window.location.replace(data?.data);
        } else if (data && !data?.redirect) {
            setSuccessSnackBarOpen(true);
        }
    }, [data]);

    useEffect(() => {
        if (error && error.status !== 301 && error?.data?.message) {
            setCustomError(error?.data?.message);
        }
    }, [error]);

    useEffect(() => {
        if (pointResponse) {
            navigate("/payment_Success");
        }
    }, [pointResponse, navigate]);

    useEffect(() => {
        if (pointError) {
            setCustomError("You have not enough point");
        }
    }, [pointError]);

    useEffect(() => {
        if (myProfile) {
            const courseFeeOff = myProfile?.userMatrimonyPackageInfo?.package?.courseDiscount || 0;
            setFeeOff(courseFeeOff);
            const discountCoursePack = myProfile?.coursePackage?.package?.discount || 0;
            setDiscount(discountCoursePack);
            // amar pay calculation
            const coursePrice = price - ((price * courseFeeOff) / 100 || 0);
            setCourseFee(coursePrice);
            setFinalPrice(coursePrice - ((coursePrice * discountCoursePack) / 100 || 0));
            // wallet calculation
            const coursePoint = priceInPoint - ((priceInPoint * courseFeeOff) / 100 || 0);
            setCourseFeeInPoint(coursePoint);
            setFinalPoint(coursePoint - ((coursePoint * discountCoursePack) / 100 || 0));
        }
    }, [myProfile, price, priceInPoint]);

    let content;
    if (paymentMethod === "amarPay") {
        content = (
            <>
                <div className="h-[80px] px-[10px] flex justify-between gap-x-[20px] items-center border-b-[1px] border-gray-200">
                    <div className="flex items-center gap-x-[4px]">
                        <img src={image} loading="lazy" alt="CourseImage" className="w-[50px] h-[30px] lg:w-[60px] lg:h-[40px]" />
                        <p className="text-[14px] font-normal font-Inter lg:text-[16px]">{name}</p>
                    </div>
                    <div className="font-Nunito">
                        {feeOff !== 0 && <del className="text-gray-400 mr-2 font-normal">{price} </del>}
                        {courseFee}
                    </div>
                </div>
                {discount !== 0 && (
                    <div className="h-[60px] px-[10px] flex justify-between items-center">
                        <span className="font-Nunito font-semibold text-[18px]">Discount</span>
                        <span className="font-medium">{discount}%</span>
                    </div>
                )}
                <div className="h-[60px] px-[10px] flex justify-between items-center">
                    <span className="font-Nunito font-semibold text-[18px]">Total Bill</span>
                    <span className="font-medium">{finalPrice}</span>
                </div>
                {/* <div className="h-[60px] px-[10px] flex justify-between items-center">
                    <span className="font-Nunito font-semibold text-[18px]">Discount</span>
                    <span className="font-medium">{myProfile?.userMatrimonyPackageInfo?.package?.courseFeeOff || 0}%</span>
                </div> */}
                {/* <div className="h-[60px] px-[10px] flex justify-between items-center">
                    <span className="font-Nunito font-semibold text-[18px]">Final Bill</span>
                    <span className="font-medium">{finalPrice}</span>
                </div> */}
            </>
        );
    } else if (paymentMethod === "point") {
        content = (
            <>
                <div className="h-[80px] px-[10px] flex justify-between gap-x-[20px] items-center border-b-[1px] border-gray-200">
                    <div className="flex items-center gap-x-[4px]">
                        <img src={image} loading="lazy" alt="CourseImage" className="w-[50px] h-[30px] lg:w-[60px] lg:h-[40px]" />
                        <p className="text-[14px] font-normal font-Inter lg:text-[16px]">{name}</p>
                    </div>
                    <div className="font-Nunito">
                        {feeOff !== 0 && <del className="text-gray-400 mr-2 font-normal">{priceInPoint} </del>}
                        {courseFeeInPoint}
                    </div>
                </div>
                {discount !== 0 && (
                    <div className="h-[60px] px-[10px] flex justify-between items-center">
                        <span className="font-Nunito font-semibold text-[18px]">Discount</span>
                        <span className="font-medium">{discount}%</span>
                    </div>
                )}
                <div className="h-[60px] px-[10px] flex justify-between items-center">
                    <span className="font-Nunito font-semibold text-[18px]">Total Bill</span>
                    <span className="font-medium">{finalPoint}</span>
                </div>
                {/* <div className="h-[60px] px-[10px] flex justify-between items-center">
                    <span className="font-Nunito font-semibold text-[18px]">Discount</span>
                    <span className="font-medium">{myProfile?.userMatrimonyPackageInfo?.package?.courseFeeOff || 0}%</span>
                </div>
                <div className="h-[60px] px-[10px] flex justify-between items-center">
                    <span className="font-Nunito font-semibold text-[18px]">Final Bill</span>
                    <span className="font-medium">{finalPoint}</span>
                </div> */}
            </>
        );
    }

    return (
        <>
            <div className="mt-[30px] w-full shadow-[0px_2px_6px_rgba(0,0,0,0.14)] p-[20px] rounded-[4px] bg-white">
                <h3 className="text-[20px] font-fira font-semibold text-black">Your order at a glance</h3>
                <div className="h-[60px] mt-[20px] rounded-[4px] w-full bg-slate-100 shadow-md flex justify-between items-center px-[10px]">
                    <p className="text-[16px] font-semibold font-Inter">Product</p>
                    <p className="text-[16px] font-semibold font-Inter">Total</p>
                </div>
                {content}

                <div className="flex items-center justify-center gap-x-[8px]">
                    <MdSecurity className="text-[14px] text-green-500" />
                    <p className="font-Inter text-[14px] text-green-500">Secure Payment</p>
                </div>
                {customError && <Error message={customError} />}
                <button
                    className={`w-full mt-[20px] lg:mt-[30px] h-[40px] flex justify-center items-center ${
                        !isSuccess ? "special_profile_button" : "special_profile_button"
                    } ${isSuccess || (!isError && "special_profile_button")} text-white duration-300 ${isError && "cursor-not-allowed"}`}
                    onClick={handleOrder}
                    disabled={isSuccess || isError}
                >
                    {isLoading || pointLoading ? (
                        <OvalLoader height={30} width={30} color="#FFFFFF" />
                    ) : isSuccess ? (
                        "Purchased"
                    ) : (
                        <span>Purchase Now</span>
                    )}
                </button>
            </div>
            {successSnackBarOpen && (
                <SuccessSnackBar
                    {...{
                        successSnackBarOpen,
                        setSuccessSnackBarOpen,
                        message: "Successful",
                    }}
                />
            )}
        </>
    );
};
