import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BundleContainer from "./BundleContainer";
import CustomHeader from "../../shared/CustomHeader/CustomHeader";
import { TopMenu } from "../../shared/Navbar/TopMenu";
import { MobileBackButton } from "../../shared/Components/MobileBackButton";

const Bundle = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the location changes
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <div className="hidden lg:block">
        <CustomHeader title="Bundle" />
      </div>
      <div className="block lg:hidden">
        <TopMenu />
        <MobileBackButton />
      </div>
      <BundleContainer />
    </div>
  );
};

export default Bundle;
